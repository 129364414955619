import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Button from "../button/Button"

import classNames from "classnames/bind";
import * as styles from "./TrainingTimes.module.css";

export default function TrainingTimes(props) {
    const cx = classNames.bind(styles);

    return (
        <StaticQuery
            query={graphql`query TrainingTimes {
                times: mdx(slug: {eq: "training-details"}) {
                    frontmatter {
                        adult
                        youth
                    }
                }
            }`}

            render={data => (
                    <ul className={cx({"home" : props.isHome})}>
                        <li>
                            <h3 className={styles.header}>Adult</h3>
                            <ul>
                                {data.times.frontmatter.adult.map(time => (
                                    <li>{time}</li>
                                ))}
                            </ul>
                            {props.isHome &&
                                <Link
                                    className={styles.button}
                                    to="/joining"
                                >
                                    <Button>
                                        Join in
                                    </Button>
                                </Link>
                            }
                        </li>
                        <li>
                            <h3 className={styles.header}>Youth</h3>
                            <ul>
                                {data.times.frontmatter.youth.map(time => (
                                    <li>{time}</li>
                                ))}
                            </ul>
                            {props.isHome &&
                                <Link
                                    className={styles.button}
                                    to="/edinburgh-jitsu-youth"
                                >
                                    <Button
                                        secondary
                                    >
                                        Learn more
                                    </Button>
                                </Link>
                            }
                        </li>
                    </ul>
            )}
        />
    );
}