import React from "react";

import classNames from "classnames/bind";
import * as styles from "./Button.module.css";

const Button = ( props ) => {

    const cx = classNames.bind(styles);

    const classes = cx(
        "wrapper",
        props.secondary ? "secondary" : "primary",
        props.className
    );

    return (
        <button 
            className={classes}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    )
}

export default Button;