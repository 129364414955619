import React from "react"
import { StaticQuery, graphql } from "gatsby"


export default function Address() {
    return (
        <StaticQuery
            query={graphql`query Address {
                address: mdx(slug: {eq: "training-details"}) {
                    frontmatter {
                        address
                        addressLink
                    }
                }
            }`}

            render={data => (
                <span><a href={data.address.frontmatter.addressLink}>{data.address.frontmatter.address}</a></span>
            )}
        />
    );
}