import React from "react"
import { StaticQuery, graphql } from "gatsby"


export default function Map() {
    return (
        <StaticQuery
            query={graphql`query Map {
                map: mdx(slug: {eq: "training-details"}) {
                    frontmatter {
                        mapUrl
                    }
                }
            }`}

            render={data => (
                <div className="iFrameWrapper">
                    <iframe src={data.map.frontmatter.mapUrl} width="600" height="450" title="map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">Loading…</iframe>
                </div>
            )}
        />
    );
}