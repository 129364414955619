exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-edinburgh-jitsu-youth-js": () => import("./../../../src/pages/edinburgh-jitsu-youth.js" /* webpackChunkName: "component---src-pages-edinburgh-jitsu-youth-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-joining-js": () => import("./../../../src/pages/joining.js" /* webpackChunkName: "component---src-pages-joining-js" */),
  "component---src-pages-ju-jitsu-js": () => import("./../../../src/pages/ju-jitsu.js" /* webpackChunkName: "component---src-pages-ju-jitsu-js" */),
  "component---src-pages-session-calendar-js": () => import("./../../../src/pages/session-calendar.js" /* webpackChunkName: "component---src-pages-session-calendar-js" */)
}

