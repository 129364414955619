import TrainingTimes from "../../../../src/components/training-times/TrainingTimes";
import Address from "../../../../src/components/address/Address";
import Map from "../../../../src/components/map/Map";
import healthPDF from "../../../../src/data/health-form.pdf";
import regPDF from "../../../../src/data/reg.pdf";
import * as React from 'react';
export default {
  TrainingTimes,
  Address,
  Map,
  healthPDF,
  regPDF,
  React
};