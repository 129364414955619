import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage } from "gatsby-plugin-image";

import classNames from "classnames/bind";
import * as styles from "./Sensei.module.css";

export default function Sensei(props) {

    const cx = classNames.bind(styles);

    const classes = cx(
        "wrapper",
        props.className
    );

    return (
        <StaticQuery
            query={graphql`query Sensei {
                sensei: allMdx(
                    filter: {frontmatter: {type: {eq: "sensei"}}}
                    sort: {fields: frontmatter___order, order: ASC}
                ) {
                    edges {
                        node {
                            frontmatter {
                                name
                                image {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                            body
                        }
                    }
                }
            }`}

            render={data => (
                <React.Fragment>
                    {props.isHome ?
                        <div className={classes}>
                            <h3>Our sensei</h3>
                            <ul>
                                {data.sensei.edges.map(instructor =>(
                                    <React.Fragment
                                        key={instructor.node.frontmatter.name}
                                    >
                                        <li>
                                            {instructor.node.frontmatter.image &&
                                                <GatsbyImage
                                                    image={instructor.node.frontmatter.image.childImageSharp.gatsbyImageData}
                                                    alt={instructor.node.frontmatter.name}
                                                    className={styles.thumbnail}
                                                />
                                            }
                                            <h4>{instructor.node.frontmatter.name}</h4>
                                        </li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    :
                        data.sensei.edges.map(instructor =>(
                            <React.Fragment
                                key={instructor.node.frontmatter.name}
                            >
                                <h3>{instructor.node.frontmatter.name}</h3>
                                <MDXRenderer>
                                    {instructor.node.body}
                                </MDXRenderer>
                            </React.Fragment>
                        ))
                    }
                </React.Fragment>
            )}
        />
    );
}